<template>
  <div>
    <div class="payBack_page">
      <div class="page_top">
        <van-image v-if="orderInfo === '1'" width="2.51rem" height="0.59rem"
                   src="https://oss.xiaoyi120.com/shop2.0/public/payBack_icon03.png"
        />
        <template v-else>
          <van-image v-if="orderInfo.mchReserved === '5'" width="2.51rem" height="0.59rem"
                     src="https://oss.xiaoyi120.com/shop2.0/public/payBack_icon01.png"
          />
          <van-image v-if="orderInfo.mchReserved === '3'" width="2.51rem" height="0.59rem"
                     src="https://oss.xiaoyi120.com/shop2.0/public/payBack_icon02.png"
          />
          <div v-if="orderInfo" class="price">￥{{ orderInfo.money }}</div>
        </template>
      </div>
      <div v-if="orderInfo !== '1'" class="page_info">
        <div class="info_msg">订单编号：{{ orderInfo.orderId }}</div>
        <div class="info_msg">支付时间：{{ orderInfo.requestTime }}</div>
        <div class="info_msg">交易流水：{{ orderInfo.origCmbOrderId }}</div>
      </div>
      <div v-else style="margin-top:0rem;font-size: 0.55rem;text-align: left;padding-left: 0.72rem;font-weight: bold;">支付成功！</div>
      <!-- <div class="page_btn">
        <div class="flex-r-sa-c">
          <van-button style="width: 3.47rem;height: 0.96rem;" round plain type="info" color="#0767AB" @click="home">返回首页
          </van-button>
          <van-button v-if="orderInfo" style="width: 3.47rem;height: 0.96rem;" round type="info" color="#0767AB"
                      @click="detail"
          >查看详情
          </van-button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getDetailByid } from '@/api/order'
export default {
  data() {
    return {
      orderInfo: {},
      sub_mch_id: '', // 特约商户号
      out_trade_no: '', // 商户订单号
      check_code: '' // md5 校验码
    }
  },
  created() {
    this.wxInit()
    this.sub_mch_id = this.$route.query.sub_mch_id
    this.out_trade_no = this.$route.query.out_trade_no
    this.check_code = this.$route.query.check_code
    this.getOrderInfo()
  },
  methods: {
    // 微信商家小票
    wxInit() {
      // 初始化小票
      var initData = {
        action: 'onIframeReady',
        displayStyle: 'SHOW_CUSTOM_PAGE'
      }
      var initPostData = JSON.stringify(initData)
      parent.postMessage(initPostData, 'https://payapp.weixin.qq.com')
    },
    // 获取订单信息
    getOrderInfo() {
      // eslint-disable-next-line no-unused-vars
      var params = {
        'id': this.out_trade_no
      }
      getDetailByid(params).then(res => {
        this.orderInfo = res.data || '1'
      })
    },
    home() {
      alert(this.orderInfo.mchReserved)
      var flag = parseInt(this.orderInfo.mchReserved)
      var url = ''
      if (flag === 5) { url = process.env.VUE_APP_BASE_HOST }
      if (flag === 3) { url = process.env.VUE_APP_BASE_HOST2 }
      if (flag === 3) { url = process.env.VUE_APP_BASE_HOST + '/#/studyClass/studyIndex' }
      window.parent.location.href = url
    },
    detail() {
      var flag = parseInt(this.orderInfo.mchReserved)
      var url = ''
      if (flag === 5) { url = process.env.VUE_APP_BASE_HOST + '/#/order/orderDetail?id=' + this.orderInfo.id }
      if (flag === 3) { url = process.env.VUE_APP_BASE_HOST2 + '/#/order/orderDetail?id=' + this.orderInfo.id }
      window.parent.location.href = url
    }
  }
}
</script>
<style lang="scss">
.payBack_page {
  width: 10rem;
  height: 8rem;
  border-radius: 0.21rem;

  background-image: url("https://oss.xiaoyi120.com/shop2.0/public/payBack_bg.png");
  background-size: 100%;

  .page_top {
    text-align: left;
    padding-top: 0.59rem;
    padding-left: 0.72rem;
    .price {
      width: 100%;
      height: 0.99rem;
      font-size: 0.69rem;
      font-weight: bold;
      line-height: 0.81rem;
      color: #333333;
    }
  }
  .page_info {
    text-align: left;
    padding-left: 0.72rem;
    .info_msg {
      width: 100%;
      height: 0.53rem;
      font-size: 0.37rem;
      font-weight: 400;
      line-height: 0.81rem;
      color: #333333;

      padding-top: 0.43rem;
    }
  }
  .page_btn {
    padding-top: 1.3rem;
  }
}
</style>
